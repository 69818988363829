<template>
  <div>
    <div class="d-flex flex-wrap align-items-center mb-2 mt-1">
      <button type="button"
              class="btn rounded-pill mr-1 mb-2"
              :class="statusQuery === null ? 'btn-primary' : 'btn-outline-primary'"
              @click="statusQuery=null"
      >
        All
      </button>
      <button v-for="(status,key) in statuses"
              :key="key"
              type="button"
              class="btn rounded-pill d-flex align-items-center mr-1 mb-2"
              :class="statusQuery === key ? 'btn-primary' : 'btn-outline-primary'"
              @click="statusQuery=key"
      >
        <span>{{ status.item1 }}</span>
        <span class="badge rounded-pill ml-1"
              :class="statusQuery === key ? 'badge-light text-primary' : 'badge-primary'">
          {{ status.item2 }}
        </span>
      </button>
    </div>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="nameQuery"
                  class="d-inline-block mr-1"
                  placeholder="Name..."
                  @keyup.enter="fetchApplications"
              />

              <b-form-input
                  v-model="emailQuery"
                  class="d-inline-block mr-1"
                  placeholder="Email..."
                  @keyup.enter="fetchApplications"
              />
              <b-button variant="secondary" @click="fetchApplications" class="mr-2">
                <span class="text-nowrap">Filter</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
          ref="applicationsTable"
          class="position-relative text-nowrap"
          :items="applications"
          responsive
          :fields="tableColumns"
          show-empty
          empty-text="No matching records found"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
      >
        <!-- Column: Name -->
        <template #cell(fullName)="data">
          <b-link class="font-weight-bold d-block text-nowrap"
                  :to="{name: 'ApplicationsShow', params: {applicationId: data.item.id}}"
          >
            {{ data.item.fullName }}
          </b-link>
        </template>

        <!-- Column: Phone -->
        <template #cell(cellPhone)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.cellPhone }}</span>
          </div>
        </template>

        <!-- Column: Active -->
        <template #cell(isActive)="data">
          <b-avatar
              size="32"
              :variant="getActiveVariant(data.item.isActive)"
          >
            <feather-icon
                :icon="getActiveIcon(data.item.isActive)"
            />
          </b-avatar>
        </template>

        <template #cell(accountCreatedOn)="data"> 
          {{ new Date(data.item.accountCreatedOn).toISOString().split('T')[0] }} 
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="toggleActive(data.item)">
              <feather-icon :icon="data.item.isActive ? 'XCircleIcon' : 'CheckCircleIcon'"/>
              <span class="align-middle ml-50">{{ data.item.isActive ? 'Deactivate' : 'Activate' }}</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{name: 'ApplicationsShow', params: {applicationId: data.item.id}}">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">Show</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
                v-model="currentPage"
                :total-rows="totalApplications"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
              </template>

              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18"/>
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BPagination, BLink, BAvatar, BBadge, BDropdown, BDropdownItem,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import ToastificationContent from '@core/components/toastification/ToastificationContent';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BLink,
    BAvatar,
    BBadge,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },

  data() {
    return {
      currentPage: 1,
      perPage: 100,
      perPageOptions: [10, 25, 50, 100],
      nameQuery: '',
      emailQuery: '',
      statusQuery: null,
      tableColumns: [
        {
          key: 'fullName',
          label: 'Name',
          sortable: true
        },
        {
          key: 'emailAddress',
          label: 'Email',
          sortable: true
        },
        {
          key: 'cellPhone',
          label: 'Phone',
          sortable: false
        },
        {
          key: 'status',
          sortable: true
        },
        {
          key: 'isActive',
          sortable: true
        },
        {
          key: 'accountCreatedOn',
          label: 'Created At',
          sortable: true
        },
        { key: 'actions' },
      ],
      sortBy: 'accountCreatedOn',
      sortDesc: true,
      applications: [],
      statuses: [],
      totalApplications: 0
    };
  },

  computed: {
    dataMeta() {
      return {
        from: this.perPage * (this.currentPage - 1) + (this.applications.length ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + this.applications.length,
        of: this.totalApplications,
      }
    },

    queryParams() {
      return {
        statuses: this.statusQuery,
        sortBy: this.sortBy,
        sortOrder: this.sortDesc ? 1 : 0,
        skip: (this.currentPage - 1) * this.perPage,
        take: this.perPage
      }
    },
  },

  watch: {
    queryParams: {
      handler() {
        this.fetchApplications();
      },
      immediate: true
    }
  },

  methods: {
    async fetchApplications() {
      const params = this.queryParams;
      params.name = this.nameQuery;
      params.email = this.emailQuery;

      const response = await this.$http.get('professionals/applications', {
        params
      });
      this.applications = response.data.results ?? [];
      this.totalApplications = response.data.count;
    },

    getActiveVariant(active) {
      return active ? 'light-success' : 'light-danger';
    },

    getActiveIcon(active) {
      return active ? 'CheckCircleIcon' : 'XIcon';
    },

    async toggleActive(user) {
      const path = `applications/${user.id}/${user.isActive ? 'deactivate' : 'activate'}`;
      await this.$http.post(path);
      const successMessage = `${user.fullName} has been ${user.isActive ? 'deactivated' : 'activated'}`;

      this.$toast({
        component: ToastificationContent,
        props: {
          title: successMessage,
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      });

      this.fetchApplications();
    },
  },

  async created() {
    const response = await this.$http.get('professionals/applications/statuses');
    this.statuses = response.data;
  }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
